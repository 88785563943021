import { Image } from 'src/components/ui/Image'
import SidebarService from 'src/components/ui/SidebarService/SidebarService'

import '../styles.scss'

const AssessoriaImprensa = () => {
  return (
    <>
      <section className="section-services assessoria_container w-full">
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="av-col-xs-24 av-col-md-7 section-services__column">
              {/* <!-SideBar CartaoPresente-> */}
              <SidebarService />
            </div>

            <div className="av-col-xs-24 av-col-md-17">
              {/* <!- CartaoPresente Products-> */}
              <div className="services-content px-3">
                <div className="section__top align-center">
                  <h1 className="text-blue italic text-2xl font-bold text-center flex items-center justify-center">
                    ASSESSORIA DE IMPRENSA
                  </h1>

                  <p className="text-xs !text-shadeOfGray text-center mt-3">
                    Confira abaixo os contatos pelo atendimento à imprensa.
                  </p>
                </div>
                <div className="hro">
                  <h2 className="text-gray italic text-lg font-semibold text-left">
                    CONTATO
                  </h2>
                  <a
                    href="mailto:gabrielle.santos@decathlon.com"
                    className="text-left text-sm mt-4 text-blue"
                  >
                    decathlon@andall.ag
                  </a>
                </div>
                <div className="hro">
                  <h2 className="text-gray italic text-lg font-semibold text-left">
                    FOTOS
                  </h2>
                  <div className="flex items-center justify-center flex-wrap gap-2">
                    <a
                      href="https://decathlonproqa.vtexassets.com/arquivos/assessoria-1.png"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="https://decathlonproqa.vtexassets.com/arquivos/assessoria-1.png"
                        alt="carousel next arrow"
                        width={200}
                        height={133}
                        className="mb-5"
                        loading="lazy"
                      />
                    </a>

                    <a
                      href="https://decathlonproqa.vtexassets.com/arquivos/assessoria-2.png"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="https://decathlonproqa.vtexassets.com/arquivos/assessoria-2.png"
                        alt="carousel next arrow"
                        width={200}
                        height={133}
                        className="mb-5"
                        loading="lazy"
                      />
                    </a>

                    <a
                      href="https://decathlonproqa.vtexassets.com/arquivos/assessoria-3.png"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="https://decathlonproqa.vtexassets.com/arquivos/assessoria-3.png"
                        alt="carousel next arrow"
                        width={200}
                        height={133}
                        className="mb-5"
                        loading="lazy"
                      />
                    </a>
                  </div>

                  <div className="flex items-center justify-center flex-wrap gap-2">
                    <a
                      href="https://decathlonproqa.vtexassets.com/arquivos/assessoria-4.png"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="https://decathlonproqa.vtexassets.com/arquivos/assessoria-4.png"
                        alt="carousel next arrow"
                        width={200}
                        height={133}
                        className="mb-5"
                        loading="lazy"
                      />
                    </a>

                    <a
                      href="https://decathlonproqa.vtexassets.com/arquivos/assessoria-5.png"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="https://decathlonproqa.vtexassets.com/arquivos/assessoria-5.png"
                        alt="carousel next arrow"
                        width={200}
                        height={133}
                        className="mb-5"
                        loading="lazy"
                      />
                    </a>

                    <a
                      href="https://decathlonproqa.vtexassets.com/arquivos/assessoria-6.png"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="https://decathlonproqa.vtexassets.com/arquivos/assessoria-6.png"
                        alt="carousel next arrow"
                        width={200}
                        height={133}
                        className="mb-5"
                        loading="lazy"
                      />
                    </a>
                  </div>

                  <div className="flex items-center justify-center flex-wrap gap-2">
                    <a
                      href="https://decathlonproqa.vtexassets.com/arquivos/assessoria-7.png"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="https://decathlonproqa.vtexassets.com/arquivos/assessoria-7.png"
                        alt="carousel next arrow"
                        width={200}
                        height={133}
                        className="mb-5"
                        loading="lazy"
                      />
                    </a>

                    <a
                      href="https://decathlonproqa.vtexassets.com/arquivos/assessoria-8.png"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="https://decathlonproqa.vtexassets.com/arquivos/assessoria-8.png"
                        alt="carousel next arrow"
                        width={200}
                        height={133}
                        className="mb-5"
                        loading="lazy"
                      />
                    </a>

                    <a
                      href="https://decathlonproqa.vtexassets.com/arquivos/assessoria-9.png"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        src="https://decathlonproqa.vtexassets.com/arquivos/assessoria-9.png"
                        alt="carousel next arrow"
                        width={200}
                        height={133}
                        className="mb-5"
                        loading="lazy"
                      />
                    </a>
                  </div>

                  <p className="font-semibold text-lg text-center">
                    ATÉ MAIS ;)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AssessoriaImprensa
